import * as React from "react"

import Seo from "../components/seo"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"

const IndexPage = () => (
  <>
    <Seo title="Home" />
    <Navbar />
    <h1>Hello gatsby</h1>
    <Footer />
  </>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
